<ngb-accordion>
  <!-- INFORMATION -->
  <ngb-panel id="raceinfo">
    <ng-template ngbPanelTitle>
      <span>Race Info - {{selectedRace.info.dateTime | date:'h:mm a'}} </span>
      <span *ngIf="selectedRace.info.status" class="text-warning"> - {{selectedRace.info.status}}</span>
      <span *ngIf="selectedRace.info.void" class="text-danger"> - Void Race</span>
      <span *ngIf="selectedRace.info.falseStart" class="text-danger"> - False Start</span>
      <span *ngIf="selectedRace.info.actualRaceTime" class="text-danger"> - Actual Race Time {{selectedRace.info.actualRaceTime | time}}</span>
      <span *ngIf="selectedRace.info.leftParadeRingTime" class="text-info"> - Left Parade Ring {{selectedRace.info.leftParadeRingTime | time}}</span>
      <span *ngIf="meta.raceInfo.loading">
        <fa name="circle-o-notch" animation="spin"></fa>
      </span>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="row">
        <div class="col">For: {{selectedRace.info.ageLimit}} <span *ngIf="selectedRace.info.sexLimit !== 'ALL'">{{selectedRace.info.sexLimit | titlecase}}</span></div>
        <div class="col">Rate: {{selectedRace.info.ratings}}</div>
        <div class="col">
          Type: {{(selectedRace.info.type | titlecase) || 'N/A'}}
          <small *ngIf="selectedRace.info.type === 'JUMP'">({{(selectedRace.info.jumpType | titlecase) || 'N/A'}})</small>
        </div>
      </div>
      <div class="row">
        <div class="col">Class: {{selectedRace.info.class}}</div>
        <div class="col">Distance: {{selectedRace.info.distance}}</div>
        <div class="col">TV: {{selectedRace.info.tvCoverage | titlecase}}</div>
      </div>
      <div class="row">
        <div class="col-4">Total Value: <button class="btn btn-link text-tooltip p-0 pb-1" placement="auto" container="body" [ngbTooltip]="racePrizes">{{selectedRace.info.totalPrize | currency:'GBP'}}</button></div>        
      </div>            
      <div class="row">
        <div class="col">
          <span>Going: </span>
          <button (click)="openGoingHistory()" class="btn btn-link p-0">
            <fa name="history"></fa>
          </button>
          <span class="text-truncate">
            {{selectedRace.info.goingText || selectedFixture.goingText || 'N/A'}}
          </span>
        </div>
      </div>           
      <div class="row">
        <div class="col" *ngIf="selectedFixture.lowSunProvision"><fa name="circle" style="color: rgb(255, 102, 0);"></fa> {{selectedFixture.lowSunProvision}}</div>
      </div>
    </ng-template>
  </ngb-panel>
  <!-- ENQUIRIES -->
  <ngb-panel id="enquiries">
    <ng-template ngbPanelTitle>
      <span>Enquiries </span>
      <span *ngIf="meta.raceEnquiries.loading">
        <fa name="circle-o-notch" animation="spin"></fa>
      </span>
      <span *ngIf="!meta.raceEnquiries.loading">
        ({{selectedRace.enquiries.length}})
      </span>
    </ng-template>
    <ng-template ngbPanelContent>
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Offence</th>
            <th scope="col">Offender</th>
            <th scope="col">Outcome</th>
            <th scope="col">Penalties</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody *ngIf="selectedRace.enquiries.length">
          <tr *ngFor="let e of selectedRace.enquiries">
            <td>
              <div class="d-flex flex-row align-items-center justify-content-start">
                <div title="racecourse" *ngIf="e.stage === 'racecourse'" class="badge badge-pill stage-racecourse mr-1">&nbsp;</div>
                <div title="disciplinary" *ngIf="e.stage === 'dp_committee'" class="badge badge-pill stage-disciplinary mr-1">&nbsp;</div>
                <div title="appeal" *ngIf="e.stage === 'appeal'" class="badge badge-pill stage-appeal mr-1">&nbsp;</div>
                <div title="court" *ngIf="e.stage === 'legal'" class="badge badge-pill stage-legal mr-1">&nbsp;</div>
                <button (click)="openEnquiry(e.enquiryId)" class="btn btn-link py-0">
                  <small>
                    {{e.offence.offence | slice:0:200}}<span *ngIf="e.offence.offence.length > 200">...</span>
                  </small>
                </button>
              </div>
            </td>
            <td><small><a [routerLink]="['/'+e.offender.type+'s', e.offender.id, 'enquiries']" target="_blank">{{e.offender.knownAs}}</a></small></td>
            <td><small>{{e.breachStatus | titlecase}}</small></td>
            <td>
              <small>
                <app-enquiry-outcomes [enquiry]="e"></app-enquiry-outcomes>
              </small>
            </td>
            <td>
              <small *ngIf="e.published === -1">Draft</small>
              <small *ngIf="e.published === 0">Unpublished</small>
              <small *ngIf="e.published === 1">Published</small>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!selectedRace.enquiries.length">
          <tr class="text-center">
            <td colspan="5">No Enquiries found for this race</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </ngb-panel>
  <!-- HORSES COMMENTS -->
  <ngb-panel id="horses-comments">
      <ng-template ngbPanelTitle>
        <span>Horses Comments </span>
        <span *ngIf="meta.horsesComments.loading">
          <fa name="circle-o-notch" animation="spin"></fa>
        </span>
        <span *ngIf="!meta.horsesComments.loading">
          ({{selectedRace.horsesComments.length}})
        </span>
      </ng-template>
      <ng-template ngbPanelContent>
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th scope="col">Date/Time</th>
              <th scope="col">Horse</th>
              <th scope="col">Comments</th>
              <!-- <th scope="col">Type</th> -->
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody *ngIf="selectedRace.horsesComments.length">
            <tr *ngFor="let hc of selectedRace.horsesComments">
              <td><span class="btn-link" (click)="openComment(hc.id)"><small>
                {{hc.dateTime | date:'dd/MM/yy h:mm'}}
                <BR><span *ngIf="(hc.updatedAt | date:'dd/MM/yy') != (hc.dateTime | date:'dd/MM/yy')">U: {{hc.updatedAt | date:'dd/MM/yy'}}</span>
                <BR><span *ngIf="(hc.createdAt | date:'dd/MM/yy') != (hc.dateTime | date:'dd/MM/yy')">C: {{hc.createdAt | date:'dd/MM/yy'}}</span>
                </small></span></td>
              <td><small><a [routerLink]="['/horses', hc.subject.id, 'enquiries']" target="_blank">{{hc.subject.knownAs}}</a></small></td>
              <td class="text-justify">
                <small *ngIf="hc.comment">{{hc.comment | slice:0:200}}<span *ngIf="hc.comment.length > 200">...</span></small>
                <span *ngIf="hc.rpcComments">
                  <span *ngIf="hc.comment && hc.rpcComments"> -- </span>
                  <small *ngFor="let rpc of hc.rpcComments; last as lastRpc">
                    {{rpc.racePerformanceComment}}
                    <span *ngIf="!lastRpc">; </span>
                  </small>
                </span>
              </td>
              <!-- <td>{{hc.type}}</td> -->
              <td>{{hc.published ? 'Published' : 'Not Published'}}</td>
            </tr>
          </tbody>
          <tbody *ngIf="!selectedRace.horsesComments.length">
            <tr class="text-center">
              <td colspan="3">No Horses Comments found for this race</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </ngb-panel>
  <!-- RACE COMMENTS -->
  <ngb-panel id="race-comments">
    <ng-template ngbPanelTitle>
      <span>Race Comments </span>
      <span *ngIf="meta.raceComments.loading">
        <fa name="circle-o-notch" animation="spin"></fa>
      </span>
      <span *ngIf="!meta.raceComments.loading">
        ({{selectedRace.comments.length}})
      </span>
    </ng-template>
    <ng-template ngbPanelContent>
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Comment</th>
            <!-- <th scope="col">Type</th> -->
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody *ngIf="selectedRace.comments.length">
          <tr *ngFor="let rc of selectedRace.comments">
            <td><button class="btn btn-link pt-0" (click)="openComment(rc.id)"><small>{{rc.createdAt | date:'dd/MM/yy'}}</small></button></td>
            <td class="text-justify"><small *ngIf="rc.comment">{{rc.comment | slice:0:200}}<span *ngIf="rc.comment.length > 200">...</span></small></td>
            <!-- <td>{{rc.type}}</td> -->
            <td>{{rc.published ? 'Published' : 'Not Published'}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="!selectedRace.comments.length">
          <tr class="text-center">
            <td colspan="3">No Comments found for this race</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </ngb-panel>
  <!-- FIXTURE COMMENTS -->
  <ngb-panel id="fixture-comments">
    <ng-template ngbPanelTitle>
      <span>Fixture Comments </span>
      <span *ngIf="meta.fixtureComments.loading">
        <fa name="circle-o-notch" animation="spin"></fa>
      </span>
      <span *ngIf="!meta.fixtureComments.loading">
        ({{selectedFixture.comments.length}})
      </span>
    </ng-template>
    <ng-template ngbPanelContent>
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Comment</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody *ngIf="selectedFixture.comments.length">
          <tr *ngFor="let fc of selectedFixture.comments">
            <td><button class="btn btn-link pt-0" (click)="openComment(fc.id)"><small>{{fc.createdAt | date:'dd/MM/yy'}}</small></button></td>
            <td class="text-justify"><small *ngIf="fc.comment">{{fc.comment | slice:0:200}}<span *ngIf="fc.comment.length > 200">...</span></small></td>
            <td>{{fc.published ? 'Published' : 'Not Published'}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="!selectedFixture.comments.length">
          <tr class="text-center">
            <td colspan="3">No Comments found for this fixture</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </ngb-panel>

</ngb-accordion>


<ng-template #racePrizes>
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th scope="col">Position</th>
        <th class="text-left" scope="col">Prize</th>
      </tr>
    </thead>
    <tbody *ngIf="selectedRace.info.prizes && selectedRace.info.prizes.length">
      <tr *ngFor="let prize of selectedRace.info.prizes; index as prizeIndex">
        <td>{{prizeIndex + 1}}</td>
        <td class="text-left">{{prize | currency:'GBP'}}</td>
      </tr>
    </tbody>
    <tbody *ngIf="!selectedRace.info.prizes || !selectedRace.info.prizes.length">
      <tr>
        <td colspan="2">Prizes details not available</td>
      </tr>
    </tbody>
  </table>
</ng-template>
